
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
  Icon,
  List,
} from "vant";
import { CollectProjectDTOS } from "@/shims.decorator";
@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [List.name]: List,
  },
})
export default class useDetails extends Mixins(Mixin) {
  public bindId = "";
  public giftCardCode = "";
  public detailList: Array<CollectProjectDTOS> = [];
  public mark = false;
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  getText(
    bizType: string | undefined,
    refundType: string | undefined,
    saleOrderCode: string | undefined
  ): string {
    let text = "";
    if (bizType === "02") {
      text = "退款-演出票";
    } else {
      text = `订单: ${saleOrderCode && saleOrderCode}`;
    }
    return text;
  }
  getData(form: { bindId: string; giftCardCode: string }): void {
    this.bindId = form.bindId;
    this.giftCardCode = form.giftCardCode;
    this.getUseDetails(true);
  }
  getUseDetails(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.memberApi.giftCard.giftCardConsumption(
      {
        id: this.bindId,
        pageNum: this.page.current,
        pageSize: this.page.size,
      },
      (data) => {
        if (this.page.current === 1) {
          this.detailList = data.data.records!;
          this.mark = true;
        } else {
          this.detailList = this.detailList.concat(data.data.records!);
        }
        this.list.loading = false;
        this.list.finished = data.data.current! >= data.data.pages!;
      }
    );
  }
  onClickLeft(): void {
    this.$emit("close");
  }
}
