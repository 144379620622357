var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"availableGift"},[_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":"可用礼品卡","left-arrow":"","right-text":"绑定新卡"},on:{"click-left":_vm.onClickLeft,"click-right":_vm.onClickRight}}),_c('div',{staticClass:"container"},[(_vm.GiftCardList.length === 0)?_c('van-row',{staticClass:"no-data"},[_c('van-image',{staticClass:"no-data-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/giftCard/no-data.png')}}),_c('van-col',{staticClass:"no-data-text"},[_vm._v("很抱歉，您暂无可以使用的卡")])],1):_c('van-checkbox-group',{attrs:{"max":20},model:{value:(_vm.checkedListIds),callback:function ($$v) {_vm.checkedListIds=$$v},expression:"checkedListIds"}},[(_vm.GiftCardList.length > 0)?_c('van-row',{staticClass:"card-list"},_vm._l((_vm.GiftCardList),function(item,index){return _c('van-row',{key:index,staticClass:"list-item"},[_c('van-col',{staticClass:"left",class:{
              check: index === _vm.activeIndex,
              uncheck: index !== _vm.activeIndex,
            }},[_c('van-checkbox',{attrs:{"checked-color":"#FFCD0F","shape":"square","name":item.giftCardCode},on:{"click":function($event){return _vm.checkItem($event, item)}}})],1),_c('van-col',{staticClass:"card-item",class:{
              effective: item.status === '1',
              invalid: item.status === '0',
            }},[_c('van-row',{staticClass:"card-top"},[_c('van-col',{staticClass:"top-left"},[_c('h3',{staticClass:"card-name"},[_vm._v(_vm._s(item.cardName))]),_c('div',{staticClass:"card-times"},[_vm._v(" 有效期： "+_vm._s(_vm.timestampFormat(Number(item.useBeginTime), "YYYY.MM.DD"))+" - "+_vm._s(_vm.timestampFormat(Number(item.useEndTime), "YYYY.MM.DD"))+" ")])])],1),_c('van-row',{staticClass:"card-bottom"},[_c('van-col',{staticClass:"bottom-left"},[_c('p',{staticClass:"balance"},[_vm._v(" 余额: "),_c('span',{staticStyle:{"font-size":"0.4rem"}},[_vm._v("￥")]),_c('span',{staticStyle:{"font-size":"0.6rem","font-weight":"500"}},[_vm._v(_vm._s(Number(item.balance).toFixed(2)))])]),_c('p',{staticClass:"theatre-name"},[_vm._v(_vm._s(item.theaterName))])])],1)],1)],1)}),1):_vm._e()],1)],1),_c('van-row',{staticClass:"bottom"},[_c('van-button',{staticClass:"btn",on:{"click":_vm.choseCard}},[_vm._v("确定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }