
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
} from "vant";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
  },
})
export default class BindGiftCard extends Mixins(Mixin) {
  public value = "";

  onClickLeft(): void {
    this.$router.go(-1);
  }

  cardInputFormatter(value: string): string {
    return this.doFormat(value);
  }

  doFormat(value: string): string {
    if (value.length < 5) {
      return value;
    }
    let prefix = value.substring(0, 4);
    let suffix = value.substring(4);
    if (suffix.indexOf(" ") == 0) {
      suffix = suffix.length < 2 ? "" : suffix.substring(1);
    }
    return prefix + " " + this.doFormat(suffix);
  }

  bindCard(): void {
    if (this.value !== "") {
      this.$api.memberApi.giftCard.bindingGiftCard(
        {
          cardPass: this.value.replaceAll(" ", ""),
        },
        () => {
          this.$toast("绑定成功");
          this.$router.go(-1);
        },
        (error) => {
          error && this.$toast(error);
        }
      );
    } else {
      this.$toast("请输入礼品卡密码");
    }
  }
}
