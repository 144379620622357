
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import useDetails from "./useDetails.vue";
import knowDialog from "@/views/Mine/AssetCenter/ElectronicWallet/Components/KnowDialog.vue";
import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
  Icon,
  Dialog,
  List,
} from "vant";
import { GiftsCardRes } from "@/shims.decorator";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [List.name]: List,
    useDetails,
    knowDialog,
  },
})
export default class GiftCard extends Mixins(Mixin) {
  public GiftCardList: Array<GiftsCardRes> = [];
  public showUserDetails = false;
  public bindId = "";
  public giftCardCode = "";
  showUseTips = false;
  tipsText = "";
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  mark = false;
  mounted(): void {
    window.scrollTo(0, 0);
    this.getUserGiftCard(true);
  }

  // 查询当前用户的礼品卡信息
  getUserGiftCard(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.memberApi.giftCard.selMyselfGiftCardInfo(
      { pageNum: this.page.current, pageSize: this.page.size },
      ({ data }) => {
        if (this.page.current === 1) {
          this.mark = true;
          this.GiftCardList = data.records as Array<GiftsCardRes>;
        } else {
          this.GiftCardList = this.GiftCardList.concat(
            data.records as Array<GiftsCardRes>
          );
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  showTips(): void {
    let name = "小贴士";
    let type = "GIFT_CARD_TIPS";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  goBindCard(): void {
    this.$router.push("/bind-card");
  }
  showDetails(id: string, code: string): void {
    let form = {
      bindId: id,
      giftCardCode: code,
    };
    this.showUserDetails = true;
    this.$nextTick(() => {
      let useDetail = this.$refs["use-detail"] as useDetails;
      if (useDetail) {
        useDetail.getData(form);
      }
    });
  }
  UseTips(item: member.GiftCardRes): void {
    this.tipsText = item.giftExplain!;
    this.showUseTips = true;
  }
  confrimTip(): void {
    this.showUseTips = false;
  }
}
